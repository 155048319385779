<template>
  <b-card-group deck>
    <b-card
      @click="$emit('on:card-click')"
      :img-src="event.activity.photo && event.activity.photo.contentUrl ? $filePath(event.activity.photo.contentUrl) : defaultSvg"
      img-alt="Card image"
      img-top
      img-height="130"
      img-width="350"
      class="card-event-detail"
      :class="canceledEvent ? 'filter-card' : ''"
    >
      <div
        class="event-card-header text-center p-1 text-white"
        :style="{backgroundColor:fullParticipants}"
      >
        <p class="mt-1 mb-1 tournament-title ">
          {{ event.name }}
        </p>
        <div class="date-title-gray text-center mb-1">
          {{ shortDay }}, {{ dayNumber }} {{ shortMonth }} • {{ event.playground.name }}
        </div>
      </div>
      <div>
        <div class="event-card-display">
          <div class=" info-card-event">
            <div class="d-flex align-content-center justify-content-between">
              <div class="mt-2 mr-2">
                <div
                  :style="varBadgeCssLinearColors ? varBadgeCssLinearColors : ''"
                  :class="varBadgeCssLinearColors ? 'badge-grey' : 'badge-grey-no-gradient'"
                >
                  <div class="display-webkit-box justify-content-center align-content-center">
                    <div
                      v-if="event.activity.icon"
                      class="svg-icon-class align-items-center"
                    >
                      <d-svg-container
                        :icon="event.activity.icon"
                        :svg-icon-style="{ fill: '#FFF', stroke: 'unset' }"
                      />
                    </div>
                    <div
                      v-else
                      class="svg-icon-chart align-items-center mr-2"
                    >
                      <i class="fa fa-bar-chart-bar"></i>
                    </div>
                    <div
                      :class="varBadgeCssLinearColors ? 'label-match ml-2' : 'label-tag-name justify-content-center align-content-center ml-2'"
                    >
                      {{ event.activity.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="badge-participant-from-custom  mt-2 text-right">
                {{ event.participantsCount }} / {{ event.maxParticipantsCountLimit }}
                {{ $t('views.events.participants') }}
              </div>
            </div>

            <div
              class="mr-2 d-flex flex-row align-content-center align-items-center">

              <div class="badge-level-from-custom mr-2  mt-2">
                <i class="fa fa-bar-chart-o"></i>
                {{ levelCheck }}
              </div>
              <div
                v-if="checkAccess"
                class="badge-level-from-custom mr-2 mt-2"
              >
                {{ checkAccess }}
              </div>
            </div>
          </div>
        </div>
        <div class="border-bottom-grey mt-3"></div>
        <div class="mt-1 d-flex justify-content-between align-items-center event-time text-center">
          <div><p class="start-end m-0"> {{ $t('views.events.event-details.startingAt') }}</p>
            <p class="start-end-time m-0">{{ startAtHour }}</p></div>
          •
          <div><p class="event-minutes-number m-0">{{ matchDuration }}</p>
            <p class="event-minutes">{{ $t('views.events.event-details.minutes') }}</p></div>
          •
          <div><p class="start-end m-0">{{ $t('views.events.event-details.endingAt') }}</p>
            <p class="start-end-time m-0">{{ endAtHour }}</p></div>
        </div>
        <div class="border-bottom-grey"></div>
        <div class="d-flex justify-content-between align-items-center event-time text-center">
          <img
            v-if="null !== eventAvatar"
            :src="eventAvatar"
            class="img-fluid image-round"
            alt
          />
          <div class="d-flex-column text-left ">
            <p class="organisator m-0"> {{ organizer }}</p>
            <p class="organisator"> {{ $t('views.events.event-details.organizer.label') }}</p>
          </div>
          <div class="list-participants d-flex align-items-center" v-if="this.event.participantsCount > 1">
            <div v-for="participant of event.participants.slice(0,2)" :key="'participant' + participant.id">
              <img
                v-if="avatar(participant)"
                :src="avatar(participant)"
                class="image-round-participant"
              >
              <template v-else>
                <d-avatar
                  :full-name="fullName(participant)"
                  size="19"
                  color="#DDDDDD"
                  text-color="#4D4F5C"
                  text-font-size="8"
                  class="mt-1 pt-1"
                />
              </template>
            </div>
            <i class="icofont icofont-plus-circle"></i>
          </div>
          <div
            class="price-a-person"
            :class="canceledEvent ? 'price-grey' : 'price-blue'"
          >
            <p class="m-0 price"> {{ event.pricePerParticipant / 100 | currency('') }}
              {{ $currency }}</p>
            <p class="m-0"> /{{ $t('views.events.event-details.person') }}</p>
          </div>

        </div>
      </div>
    </b-card>
  </b-card-group>
</template>
<script>
import {capitalize} from "@/utils/string";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

const CANCELED_MATCH = '#B0B0B0';
const COMPLETE_MATCH = '#E44D2E';
const NOT_COMPLETE_MATCH = '#50C878';

export default {
  props: {
    event: {
      type: Object,
      default: () => {
      }
    },
    canceledEvent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    eventAvatar () {
      if (null !== this.event.userClient) {
        if (isNotUndefinedAndNotNull(this.event.userClient.avatar)) {
          return this.$filePath(this.event.userClient.avatar.contentUrl);
        }
      } else {
        if (null !== this.club.logo) {
          if (isNotUndefinedAndNotNull(this.club.logo.contentUrl)) {
            return this.$filePath(this.club.logo.contentUrl);
          }
        }
      }

      return null;
    },
    organizer() {
      if (null !== this.event.userClient) {
        return this.event.userClient.firstName + ' ' + this.event.userClient.lastName;
      } else {
        return this.club.name;
      }
    },
    checkAccess() {
      if (this.event.access === "for_guest") {
        return this.$t('views.events.event-details.organizer.private');
      } else {
        return null;
      }
    },

    levelCheck() {
      if (this.event.activityLevels.length > 1) {
        return this.event.activityLevels[0].label + " " + "-" + " " + this.event.activityLevels[1].label
      } else {
        return this.event.activityLevels[0].label
      }
    },
    varBadgeCssLinearColors() {
      if (this.canceledEvent) {
        return 'background-color: #A9A9A9'
      } else {
        if (this.event.activity.colors.length > 0) {
          return {
            '--firstColor': this.event.activity.colors[0],
            '--secondColor': this.event.activity.colors[1],
          }
        } else {
          return null;
        }
      }
    },
    fullParticipants() {
      if (this.canceledEvent) {
        return CANCELED_MATCH
      } else {
        if (this.event.maxParticipantsCountLimit === this.event.participantsCount) {
          return COMPLETE_MATCH
        } else {
          return NOT_COMPLETE_MATCH
        }
      }
    },
    startAtHour() {
      return this.$moment(this.event.startAt).format('HH:mm');
    },
    endAtHour() {
      return this.$moment(this.event.endAt).format('HH:mm');
    },
    defaultSvg() {
      return require('@/assets/images/playground/ban.svg')
    },
    shortDay() {
      const day = this.$moment(this.event.startAt).format('ddd');
      return capitalize(day).slice(0, -1);
    },
    shortMonth() {
      const day = this.$moment(this.event.startAt).format('MMM');
      return capitalize(day).slice(0, 3);
    },
    hour() {
      return this.$moment(this.event.startAt).format('HH:mm');
    },
    dayNumber() {
      return this.$moment(this.event.startAt).format('DD');
    },
    matchDuration() {
      const startTime = this.$moment(this.event.startAt)
      const finishTime = this.$moment(this.event.endAt)
      return finishTime.diff(startTime, 'minutes')
    },
    club () {
      return this.$store.getters["auth/currentClub"];
    }
  },
  methods: {
    avatar(participant) {
      if (participant.client !== null && participant.user === null) {
        if ("undefined" !== typeof (participant.client.identityPhoto)) {

          return isNotUndefinedAndNotNull(participant.client.identityPhoto.contentUrl) ?
            this.$filePath(participant.client.identityPhoto.contentUrl) : null

        }
      } else if (participant.user !== null && participant.client === null && participant.user.avatar !== null) {
          return isNotUndefinedAndNotNull(participant.user.avatar.contentUrl) ?
            this.$filePath(participant.user.avatar.contentUrl) : null
      }

      return null
    },
    fullName(participant) {
      if (participant.client !== null && participant.client.identityPhoto === null) {
        return participant.client.firstName + " " + participant.client.lastName
      } else if (participant.user !== null && participant.user.avatar === null) {
        return participant.user.firstName + " " + participant.user.lastName
      } else if (participant.user === null && participant.client === null) {
        return "Client"
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/events/matches/_event-card.scss";

</style>
