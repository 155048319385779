import { render, staticRenderFns } from "./EventCard.vue?vue&type=template&id=9d8ca97a&scoped=true&"
import script from "./EventCard.vue?vue&type=script&lang=js&"
export * from "./EventCard.vue?vue&type=script&lang=js&"
import style0 from "./EventCard.vue?vue&type=style&index=0&id=9d8ca97a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d8ca97a",
  null
  
)

export default component.exports